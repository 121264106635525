//REBOOT
@font-face {
  font-family: 'site-font';
  src: url('/assets/fonts/reg.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'site-font';
  src: url('/assets/fonts/reg-italic.woff2');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'site-font';
  src: url('/assets/fonts/bold.woff2');
  font-weight: 700;
}
html,
body {
  height: 100%;
}
body {
  font-family: 'Bodoni Moda', serif;
  // font-family: 'site-font', 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  //   'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: $black;
  font-size: 12px !important;
  line-height: 1.8;
  @media (min-width: $desktop) {
    font-size: 16px !important;
    line-height: 2.2;
  }
}

.container-fluid {
  @media (min-width: $desktop) {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.main-content {
  padding-bottom: 100px;
}

.row {
  @media (min-width: $desktop) {
    margin-left: -4.5%;
    margin-right: -4.5%;
  }
}

[class^='col-'] {
  @media (min-width: $desktop) {
    padding-left: 4.5%;
    padding-right: 4.5%;
  }
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}
